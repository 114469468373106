import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from 'axios'

import { Button, OsnSelect } from "@identitybuilding/idb-react-ui-elements";
import Icon from "@identitybuilding/idb-react-iconlib";

import Navigation from "./Navigation"
import UIT from "./UIT"
import ShareButtons from "../components/ShareButtons"
import Footer from "../components/Footer"
import '../assets/css/DetailsPage.css'
import { removeFollowingEstablishment, toFollowEst, toggleLoginScreen, toggleOpenFollowingConflict } from '../actions/index'
import { useDispatch } from 'react-redux'
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // An function that increment 👆🏻 the previous state like here 
    // is better than directly setting `value + 1`
}
const createNotification = (type, message) => {
    if (type === "info") {
        NotificationManager.info(message);
    } else if (type === "success") {
        NotificationManager.success(message);
    } else if (type === "warning") {
        NotificationManager.warning(message);
    } else if (type === "error") {
        NotificationManager.error(message);
    }
};


const DetailsPage = (props) => {
    const translate = useSelector((state) => state.general.translate);
    const lang = useSelector((state) => state.general.lang);
    const forceUpdate = useForceUpdate();
    const { loginScreen, accountData } = useSelector(state => state.account);
    const dispatch = useDispatch();
    const [data, setData] = useState("");
    const [CMPData, setCMPData] = useState("");
    const [page, setPage] = useState("cmps");
    const [cmpsCollections, setCmpsCollections] = useState([]);
    const [bpIndex, setBpIndex] = useState("all");
    const [menu, setMenu] = useState(false);
    const [share, setShare] = useState(false);
    const [followers, setFollowers] = useState("");
    const [menuItems, setMenuItems] = useState([]);
    const [sortParams, setSortParams] = useState(translate('recent_date'));
    const [uitArray, setUitArray] = useState([]);


    useEffect(() => {
        let query = props.match.params.municipality.replace('brussel', 'brussel-stad')
        let option = {
            from: 0,
            size: 30,
            query: {
                query_string: {
                    query: query,
                    fields: [
                        'slug',
                        'name_nl',
                        'name_fr',
                        'name_de',
                        'name_en',
                        'alt_name_nl',
                        'alt_name_fr',

                    ],
                    tie_breaker: 0.3,
                },
            },
        };
        axios.get(`https://elasticsearch.100procentlokaal.be/municipalities/_search`,
            {
                auth: {
                    username: `${process.env.REACT_APP_ESUSERNAME}`,
                    password: `${process.env.REACT_APP_ESPSW}`,
                },
                params: {
                    source_content_type: "application/json",
                    source: JSON.stringify(option),
                },
            }
        ).then((response) => {
            let data = response.data.hits.hits[0]._source
            axios.get(`https://elasticsearch.100procentlokaal.be/_search?q=search_id:${response.data.hits.hits[0]._source.establishment.number}`,
                {
                    auth: {
                        username: `${process.env.REACT_APP_ESUSERNAME}`,
                        password: `${process.env.REACT_APP_ESPSW}`,
                    },
                    params: {
                        source_content_type: "application/json",
                        source: JSON.stringify(option),
                    },
                }).then((resp) => {
                    let res = resp.data.hits.hits[0]._source
                    data.establishment_name = res[`name_${lang}`] || res.name_nl || res.name_fr || res.name_de || res.name_en
                    data.municipality = res.address[`municipality_${lang}`] || res.address.municipality_nl || res.address.municipality_fr || res.address.municipality_de || res.address.municipality_en
                    data.municipality = data.municipality.replace('(Ville)', '').replace('(Stad)', '')
                    // data.cmp_collectionlinks = res.cmp_collectionlinks
                    data.search_id = res.search_id
                    let copy_cmps = []
                    let menu = []

                    axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/business/${res.number}/cmps/?lang=${lang}`)
                        .then((respon) => {
                            respon.data.establishment_cmp_collections.map((collection) => {
                                menu.push(collection.cmp_collection.name)
                                collection.cmp_collection.cmps.map((cmp) => {
                                    if (cmp.cmp_type === "MAGAZINE") { copy_cmps.push(cmp) }

                                })
                            })
                            respon.data.enterprise_cmp_collections.map((collection) => {
                                menu.push(collection.cmp_collection.name)
                                collection.cmp_collection.cmps.map((cmp) => {
                                    if (cmp.cmp_type === "MAGAZINE") { copy_cmps.push(cmp) }

                                })
                            })
                            respon.data.group_cmp_collections.map((collection) => {
                                menu.push(collection.cmp_collection.name)
                                collection.cmp_collection.cmps.map((cmp) => {
                                    if (cmp.cmp_type === "MAGAZINE") { copy_cmps.push(cmp) }

                                })
                            })
                            let sorted_cmps = copy_cmps.sort(function (a, b) {
                                let afrom = a.valid_start.split('-')
                                let bfrom = b.valid_start.split('-')
                                let adate = new Date(afrom[2], Number(afrom[1] - 1), afrom[0])
                                let bdate = new Date(bfrom[2], Number(bfrom[1] - 1), bfrom[0])
                                // sort on recent date
                                if (adate > bdate) { return -1 }
                                if (adate < bdate) { return 1 }


                            });
                            setMenuItems(menu)
                            setCmpsCollections(sorted_cmps)
                            // if (copy.followingestablishments_set != null) {
                            //     debugger;
                            //     copy.following = copy.followingestablishments_set.find(e => e === res.data.establishment.number) != null;
                            //   }

                            setFollowers(respon.data.promo_followers)
                            setData(data)
                            setCMPData(respon.data)
                        })



                    axios.get(`https://search-test.uitdatabank.be/offers/?postalCode=${data.pkancode}&apiKey=1a1a3edc-69c0-4619-ac0f-31b2c376425c`)
                        .then((res) => {
                            setUitArray(res.data.member)
                        })

                })
        })

    }, [])

    useEffect(() => {

    }, [])

    const followHandler = () => {
        if (accountData && accountData.followingestablishments_set && data.establishment) {

            const currentlyFollowing = accountData.followingestablishments_set.includes(data.establishment.number);
            let accountCopy = { ...accountData };

            if (currentlyFollowing) {
                accountCopy.followingestablishments_set = accountCopy.followingestablishments_set.filter(e => e !== data.establishment.number);
            } else {
                accountCopy.followingestablishments_set.push(data.establishment.number);
            }


            const headers = {
                "Content-Type": "application/json",
                Authorization: `Token ${localStorage.getItem("StadGemeenteToken")}`,
            };

            axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/catalogus/follow_establishment/${data.establishment.number}/?lang=${lang}`, {
                headers: headers,
                token: localStorage.getItem("StadGemeenteToken"),
                following: !currentlyFollowing,
            }).then(() => {
                let copy = data
                copy.following = !copy.following;
                setData(copy)
                dispatch(removeFollowingEstablishment(accountCopy))
                if (currentlyFollowing) { setFollowers(followers - 1) }
                else { setFollowers(followers + 1) }

                forceUpdate();

            }).catch((err) => {
                if (err.response.data.Status === 'Duplicate group') {
                    err.response.data.establishment.current_est = data
                    dispatch(toggleOpenFollowingConflict(err.response.data.establishment))
                }
            })
        }
        else {
            dispatch(toFollowEst(data.establishment.number))
            dispatch(toggleLoginScreen(!loginScreen))
            createNotification("warning", translate('please_log_in_first'))
        }
    }
    const changeSort = (e) => {
        setSortParams(e.name)
        let sorted_cmps = []
        let copy_cmps = cmpsCollections

        sorted_cmps = copy_cmps.sort(function (a, b) {
            let afrom = a.valid_start.split('-')
            let bfrom = b.valid_start.split('-')
            let adate = new Date(afrom[2], Number(afrom[1] - 1), afrom[0])
            let bdate = new Date(bfrom[2], Number(bfrom[1] - 1), bfrom[0])
            // // sort on recent date
            // if (adate > bdate) { return -1 }
            // if (adate < bdate) { return 1 }
            if (e.name === translate("recent_date")) {
                if (adate > bdate) { return -1 }
                if (adate < bdate) { return 1 }
            }
            if (e.name === translate("oldest_date")) {
                if (adate > bdate) { return 1 }
                if (adate < bdate) { return -1 }
            }
            if (e.name === translate("a-z")) {
                if ((a.name_nl > b.name_nl) || (a.name_fr > b.name_fr) || (a.name_de > b.name_de) || (a.name_en > b.name_en)) { return 1 }
                if ((a.name_nl < b.name_nl) || (a.name_fr < b.name_fr) || (a.name_de < b.name_de) || (a.name_en < b.name_en)) { return -1 }
            }

            // sort unalphabetical
            if (e.name === translate("z-a")) {
                if ((a.name_nl > b.name_nl) || (a.name_fr > b.name_fr) || (a.name_de > b.name_de) || (a.name_en > b.name_en)) { return -1 }
                if ((a.name_nl < b.name_nl) || (a.name_fr < b.name_fr) || (a.name_de < b.name_de) || (a.name_en < b.name_en)) { return 1 }
            }

        });

        setCmpsCollections(sorted_cmps)
        forceUpdate();
    }

    const changeCollection = (index) => {
        let collections = CMPData.establishment_cmp_collections.concat(CMPData.enterprise_cmp_collections, CMPData.group_cmp_collections);
        let sorted_cmps = []
        let copy_cmps = []
        if (index === 'all') {
            collections.forEach((collection) => {
                // data.cmp_collectionlinks.forEach((collection) => {
                collection.cmp_collection.cmps.forEach((cmp) => {
                    if (cmp.cmp_type === "MAGAZINE") { copy_cmps.push(cmp) }

                })
            })
            sorted_cmps = copy_cmps.sort(function (a, b) {
                let afrom = a.valid_start.split('-')
                let bfrom = b.valid_start.split('-')
                let adate = new Date(afrom[2], Number(afrom[1] - 1), afrom[0])
                let bdate = new Date(bfrom[2], Number(bfrom[1] - 1), bfrom[0])
                // sort on recent date
                if (adate > bdate) { return -1 }
                if (adate < bdate) { return 1 }


            });

        }
        else {
            collections[index].cmp_collection.cmps.forEach((cmp) => {
                if (cmp.cmp_type === "MAGAZINE") { copy_cmps.push(cmp) }
            })
            // sort
            sorted_cmps = copy_cmps.sort(function (a, b) {
                let afrom = a.valid_start.split('-')
                let bfrom = b.valid_start.split('-')
                let adate = new Date(afrom[2], Number(afrom[1] - 1), afrom[0])
                let bdate = new Date(bfrom[2], Number(bfrom[1] - 1), bfrom[0])
                // sort on recent date
                if (adate > bdate) { return -1 }
                if (adate < bdate) { return 1 }


            });
        }
        setCmpsCollections(sorted_cmps)
        setBpIndex(index)
    }

    return (
        data &&
        <div id="detailPage">
            <Navigation {...props} type={data.is_city ? 'city' : 'municipality'} />
            {share && <ShareButtons createNotification={createNotification} setShare={(e) => setShare(e)} />}

            <div id="detailContent" className={`${cmpsCollections.length > 0 ? '' : 'empty'}`} >
                <Icon onClick={() => setMenu(true)} className="hamburgerIcon" name="MenuFill" />
                <div className={`detailSidebar ${menu ? 'active' : ''}`}>
                    <Icon onClick={() => setMenu(false)} className="closeIcon" name="Close" />
                    <div className="logo">
                        {data.logo ?
                            <img src={data.logo[`png_${lang}`] || data.logo.png_nl || data.logo.png_fr || data.logo.png_de || data.logo.png_en} alt="logo gemeente" />
                            :
                            <img src="" alt="logo gemeente" />
                        }
                    </div>
                    <div className="info" style={{ "height": "100px" }}>
                        {data.is_city ?
                            <h3 style={{ "height": "100px" }}>{translate('city')} {data[`name_${lang}`] || data.name_nl || data.name_fr || data.name_de || data.name_en}</h3>
                            :
                            <h3 style={{ "height": "100px" }}>{translate('municipality')} {data[`name_${lang}`] || data.name_nl || data.name_fr || data.name_de || data.name_en}</h3>
                        }
                        {/* <Button
                            borderColor='cn'
                            text={translate('follow')}
                            txtColor='cn'
                            type='sub'
                            size='S'
                        /> */}
                        <div className="sideNavButtons">
                            {data && <div className={`following_button ${accountData?.followingestablishments_set?.includes(data.establishment.number) ? 'following' : ''
                                }`} onClick={() => followHandler()}>
                                {accountData?.followingestablishments_set?.includes(data.establishment.number) ? translate('following') : translate('follow')}
                            </div>}
                            {data && <div className={`following_button`} onClick={() => setShare(true)}>
                                {translate('share')}
                            </div>}
                        </div>
                        {<span className="followers">({followers} {translate('followers')})</span>}
                    </div>
                    <ul>
                        <li className="active">
                            <a onClick={() => { changeCollection('all'); setPage('cmps') }} className={`${bpIndex === 'all' ? 'active' : ''}`}>{translate('info_and_magazines')}</a>
                            <div className="linkChildren">
                                {menuItems.length > 1 &&
                                    menuItems.map((item, index) => (
                                        <span className={`${bpIndex === index ? 'active' : ''}`} key={index} onClick={() => { changeCollection(index); setPage('cmps') }}>{item}</span>

                                    ))
                                }
                            </div>
                        </li>
                        <li>
                            <a className="osn" target="_blank" rel="noopener noreferrer" href={`https://${data.slug}.${lang === 'nl' ? '100procentlokaal' : '100pourcentlocale'}.be/business/${data.search_id}/${data.establishment_name}/contact`}>{translate('business_page')}</a>
                        </li>
                        <li>
                            <a className={`uit ${bpIndex === 'uit' ? 'active' : ''}`} onClick={() => { setPage('uitvlaanderen'); setBpIndex('uit') }}>UIT in {data.municipality}</a>
                        </li>
                    </ul>
                    <div className="buttonText">
                        <span><Icon name="Info" /> {translate('search_for_all_enterprises')} {data.municipality}</span>
                        <Button
                            borderColor='cn'
                            text={`${translate('go_to')} ${data.municipality} 100% ${lang === 'nl' ? 'lokaal' : 'locale'}`}
                            txtColor='cn'
                            type='sub'
                            size='S'
                            url={`https://${data.pkancode}.${lang === 'nl' ? '100procentlokaal' : '100pourcentlocale'}.be`}
                            style={{ paddingLeft: '10px', paddingRight: '10px', color: 'red' }}
                        />
                    </div>
                </div>
                {page === 'cmps' ?
                    <div className="detailsMain">
                        {cmpsCollections.length > 0 ?
                            <React.Fragment>
                                {bpIndex !== "all" ? <h2>{menuItems[bpIndex]}</h2> : <h2></h2>}
                                <div className="MagazineHeader">
                                    <span className="magazineButton">{translate('publications')}</span>
                                    <OsnSelect
                                        className="lang-select"
                                        onChange={(e) => changeSort(e)}
                                        active={sortParams}
                                        options={[
                                            { id: 0, name: translate('recent_date') },
                                            { id: 1, name: translate('oldest_date') },
                                            { id: 2, name: translate('a-z') },
                                            { id: 3, name: translate('z-a') }
                                        ]}
                                    />
                                </div>

                                <div className="cmp_collection_wrapper">
                                    {cmpsCollections.map((cmp, index) => (
                                        <div key={index} className="cmp_wrapper_cn">
                                            <a className="myWrapper pn" target="_blank" href={cmp.cat_network_url || cmp[`cat_network_url_${lang}`] || cmp.cat_network_url_nl || cmp.cat_network_url_fr || cmp.cat_network_url_de || cmp.cat_network_url_en}>
                                                <div className="dd magazine">
                                                    <img src={cmp.thumbnail || cmp[`thumbnail_${lang}`] || cmp.thumbnail_nl || cmp.thumbnail_fr || cmp.thumbnail_de || cmp.thumbnail_en} alt={cmp.name} />
                                                    <h4>{cmp.name || cmp[`name_${lang}`] || cmp.name_nl || cmp.name_fr || cmp.name_de || cmp.name_en}</h4>
                                                    <div className="ddOverlay"></div>
                                                </div>
                                                <h5>
                                                    {cmp.valid_start || cmp[`valid_start_${lang}`] || cmp.valid_start_nl || cmp.valid_start_fr || cmp.valid_start_de || cmp.valid_start_en}
                                                    <span>
                                                        <Icon name="Magazines" />
                                                    </span>
                                                    {cmp.valid_end || cmp[`valid_end_${lang}`] || cmp.valid_end_nl || cmp.valid_end_fr || cmp.valid_end_de || cmp.valid_end_en}
                                                </h5>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </React.Fragment>
                            :
                            <div className={`noCmps ${data.is_city ? 'city' : ''}`}>
                                {data.is_city ?
                                    <h3>{translate('this_city_is_not_sharing_its_books_here_yet')}</h3>
                                    :
                                    <h3>{translate('this_municipality_is_not_sharing_its_books_here_yet')}</h3>
                                }
                                <figure>
                                    <img src={require('../assets/imgs/Plenny_wenen.png')} alt="Plenny being sad" />
                                </figure>
                            </div>
                        }
                    </div>
                    :
                    <div className="detailsMain uit">
                        {uitArray.map((res, index) => (
                            <UIT key={index} uit_data={res} data={data} />
                        ))}
                    </div>}
            </div>
            <Footer {...props} />
        </div>
    )
}

export default DetailsPage