import Map from "@identitybuilding/idb-react-100-percent-local"

import { useHistory } from "react-router-dom";
import React from "react";
import Navigation from "./Navigation";
import { useSelector, useDispatch } from "react-redux"; 
import { toggleLoginScreen } from "../actions/AccountAction";
const Main = () => {
  const history = useHistory();
  const dispatch = useDispatch(); // Initialize dispatch
  const lang = useSelector((state) => state.general.lang);
  const loginScreen = useSelector((state) => state.account.loginScreen); // Access loginScreen state
  const accountData = useSelector((state) => state.account.accountData); // Determine if logged in

  const goToPage = (e) => {

    let municipalityName = e["Name" + lang.toUpperCase()] ? e["Name" + lang.toUpperCase()].toLowerCase()
      .replace(" (stad)", '')
      .replace(" (ville)", '')
      .replace(" (stadt)", '')
      .replace(" city", '')
      .replace(/\ /g, '')
      .replace("'", '')
      .replace("lalouviere", "la louvière")
      .replace("lacalamine", "la calamine")
      .replace("labruyere", "la-bruyere")
      .replace("lahulpe", "la hulpe")
      .replace("fontaine-lévêque", 'fontaine-levêque')
      :
      e.name
        .replace(" (stad)", '')
        .replace(" (ville)", '')
        .replace(" (stadt)", '')
        .replace(" city", '')
        .replace(/\ /g, '')
        .replace("'", '')
        .replace("lalouviere", "la louvière")
        .replace("lacalamine", "la calamine")
        .replace("labruyere", "la-bruyere")
        .replace("lahulpe", "la hulpe")
        .replace("fontaine-lévêque", 'fontaine-levêque')
    if (municipalityName === 'sgravenbrakel') { municipalityName = 's-gravenbrakel' }
    if (municipalityName === 'mont-de-lenclus') { municipalityName = 'montdelenclus' }
    if (municipalityName === 'lesbonsvillers') { municipalityName = 'les-bons-villers' }
    if (window.location.hostname === 'localhost') {
      history.push(`/${municipalityName.toLowerCase()}`);
    }
    else {
      window.open(`https://${window.location.hostname}/${municipalityName.toLowerCase()}`)
    }


  }

  return (
    <div>
      <Navigation lang={lang} />
      <Map lang={lang} MyCity={true} getData={(e) => goToPage(e)}   loggedIn={Boolean(accountData)} login={() => {
        dispatch(toggleLoginScreen(!loginScreen)); // Toggle login screen state
      }} />
    </div>
  )
}

export default Main