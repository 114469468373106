const initialState = {
    loginScreen: false,
    accountData: "",
    openFollowingConflict: false,
    toFollowEstablishment: "",
}

const AccountReducer = (state = initialState, action) => {

    switch (action.type) {
        case "TOGGLE_LOGIN_SCREEN": {
            return {
                ...state,
                loginScreen: action.value
            }
        }
        case "UPDATE_ACCOUNT_INFO": {
            return {
                ...state,
                accountData: action.value
            }
        }
        case "REMOVE_FROM_SAVED_CMPS": {
            let copy = state.accountData
            copy.saved_cmps.splice(action.index, 1)
            return {
                ...state,
                accountData: copy
            }
        }
        case "REMOVE_FOLLOWING_ESTABLISHMENT": {
            return {
                ...state,
                accountData: action.value
            }
        }
        case "TOGGLE_OPEN_FOLLOWING_CONFLICT": {
            return {
                ...state,
                openFollowingConflict: action.value
            }
        }
        case "TO_FOLLOW_EST": {
            return {
                ...state,
                toFollowEstablishment: action.value
            }
        }
        default:
            return state;
    }
}

export default AccountReducer;