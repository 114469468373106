import './assets/css/App.css';
import "@identitybuilding/idb-react-ui-elements/dist/styles/Colors.css";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import Main from "./components/Main"
import DetailsPage from "./components/DetailsPage"
import Login from './components/Login';
import Meta from './components/Meta';
// import SavedPage from "./pages/SavedPage";
// import FollowingPage from "./pages/FollowingPage"
// import ShoppingList from "./pages/ShoppingList"
// import PasswordReset from "./pages/PasswordReset"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useEffect } from 'react';
import CookieMessage from './components/CookieMejsxssage';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import FollowingConflict from './components/FollowingConflict';
import { updateAccountInfo, toggleLoginScreen as toggleLoginScreenAction } from './actions/index';
import axios from 'axios';

const App = () => {
  const { translate, lang } = useSelector(state => state.general);
  const accountData = useSelector((state) => state.account.accountData); // Determine if logged in

  const { loginScreen, openFollowingConflict } = useSelector(state => state.account);
  const dispatch = useDispatch()
  const toggleLoginScreen = () => {
    dispatch(toggleLoginScreenAction(false)); // Ensure proper dispatch of action
  };
  let page_slug = translate('page').toLowerCase()

  const getData = () => {
    if (localStorage.getItem("StadGemeenteToken")) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("StadGemeenteToken")}`,
      };
      axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/catalogus/user/?lang=${lang}`, {
        headers: headers,
      })
        .then((res) => {
          let copy = res.data
          const today = new Date(); // current date
          const filteredArray = copy.saved_cmps.filter(item => {
            // Parse date strings in "DD-MM-YYYY" format
            const parseDate = dateString => {
              const [day, month, year] = dateString.split('-').map(Number);
              return new Date(year, month - 1, day); // Month is 0-indexed in JavaScript Dates
            };

            const visibleEnd = parseDate(item.visible_end.substr(0, 10));

            // Calculate two weeks after visible_end
            const twoWeeksAfterEnd = new Date(visibleEnd);
            twoWeeksAfterEnd.setDate(visibleEnd.getDate() + 14);

            // Check if today is within two weeks after visible_end
            return today <= twoWeeksAfterEnd;
          });
          copy.saved_cmps = filteredArray

          dispatch(updateAccountInfo(res.data))
        })
    }
  }


  useEffect(() => {

    // if(window.location.hostname !== 'localhost' && (!window.location.href.includes('mijn') || !window.location.href.includes('ma'))) {
    //   if (window.location.hostname.includes('stad-gemeente')){
    //     window.location.href = `https://mijn.stad-gemeente.be${window.location.pathname}`
    //   }
    //   else {
    //     window.location.href = `https://mijn.stad-gemeente.be${window.location.pathname}`
    //   }
    // }
    if (window.location.hostname !== 'localhost') {
      if (window.location.hostname.includes("stad-gemeente.be")) {
        if (!window.location.hostname.includes("mijn")) {
          window.location.href = `https://mijn.stad-gemeente.be${window.location.pathname}`
        }
      }
      else if (window.location.hostname.includes("ville-commune.be")) {
        if (!window.location.hostname.includes("ma")) {
          window.location.href = `https://ma.ville-commune.be${window.location.pathname}`
        }
      }
    }

    getData()

  }, [])

  const createNotification = (type, message) => {
    if (type === "info") {
      NotificationManager.info(message);
    } else if (type === "success") {
      NotificationManager.success(message);
    } else if (type === "warning") {
      NotificationManager.warning(message);
    } else if (type === "error") {
      NotificationManager.error(message);
    }
  };

  return (
    <div className="App">
      <NotificationContainer />
      <BrowserRouter>
        <Meta />
        {loginScreen && <Login createNotification={createNotification} toggleLoginScreen={toggleLoginScreen} />}
        {openFollowingConflict && <FollowingConflict
          createNotification={createNotification} // Pass the createNotification function here
          setShare={() => { }} // Ensure setShare is passed if needed
        />}

        <CookieMessage />
        <Switch>
          <Route exact path="/" render={(props) => <Main {...props} dispatch={dispatch} toggleLoginScreen={toggleLoginScreen} loggedIn={!!accountData} />} />
          <Route path="/:municipality" render={(props) => <DetailsPage {...props} />} />
          {/* <Route path="/saves/" render={(props) => <SavedPage {...props} createNotification={createNotification} />} />
          <Route path="/following/" render={(props) => <FollowingPage {...props} createNotification={createNotification} />} />
          <Route path="/shoppinglist/" render={(props) => <ShoppingList {...props} createNotification={createNotification} />} />
          <Route path="/reset_password/:token/" render={(props) => <PasswordReset {...props} createNotification={createNotification} />} /> */}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
